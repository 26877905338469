<template>
  <div class="modal fade" id="myModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <!-- 模态框头部 -->
        <div class="modal-header">
          <h4 class="modal-title" v-if="!(title === '')">{{ title }}</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- 模态框主体 -->
        <div class="modal-body">
          {{ content }}
        </div>

        <!-- 模态框底部 -->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="handleCancel()" v-if="!(cancel === '')" @keyup.enter.down="handleCancel">{{ cancel }}</button>
          <button type="button" class="btn btn-primary" v-if="!(ok === '')" @click="handleOk()">{{ ok }}</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Modal",
    }
</script>

<style scoped>

</style>
