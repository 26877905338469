<template>
<!--  <div class="row m-0 mt-md-3 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">联系我们</div>
      <div class="list-group p-0">
        <a href="#" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">联系我们</a>
      </div>
      <div class="card-footer text-left animate__animated animate__slideInUp">
        <div class="pt-4 pb-4">
          <h6><i class="fa fa-user" aria-hidden="true"></i>  联系人：中管院艺术发展中心管理员</h6>
          <h6><i class="fa fa-envelope" aria-hidden="true"></i> 邮箱：2535161166@qq.com</h6>
          <h6><i class="fa fa-map-marker" aria-hidden="true"></i>  联系地址：北京市海淀区西小口文龙家园二里8号楼6单元801室</h6>
        </div>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp">
      <form class="text-left w-75">
        <div class="form-group">
          <label for="tel">联系电话</label>
          <input type="tel" class="form-control" v-model="msgForm.tel" id="tel" aria-describedby="tel">
          <small id="emailHelp" class="form-text text-mute text-danger">请输入您的联系电话，方便我们联系您。</small>
        </div>
        <div class="form-group">
          <label for="msgName">姓名</label>
          <input type="text" class="form-control" v-model="msgForm.msgName" id="msgName">
        </div>
        <div class="form-group">
          <label for="context">留言</label>
          <textarea class="form-control" id="context"  v-model="msgForm.context" rows="5"></textarea>
        </div>
        <button type="button" class="btn btn-primary pl-5 pr-5 " @click="newMsg()">提  交</button>
      </form>
      <!-- Modal -->

    </div>
<!--    <Modal :Modals="Modals"/>-->
  </div>
</template>

<script>
import { modalBox } from '../util'
import $ from 'jquery'
export default {
  name: "message",
  data() {
    return {
      msgForm: {
        tel: '',
        msgName: '',
        context: ''
      },
      // Modal: []
    }
  },
  methods: {
    newMsg() {
      if (this.msgForm.tel === '') {
        modalBox({
          title: '提示',
          content: "请问您留下联系方式，方便我们联系您",
          ok: '好的',
        })
        $("#myModal").modal('show')
        return
      }
      if (this.msgForm.msgName === '') {
        modalBox({
          title: '提示',
          content: "请问怎么称呼呢？",
          ok: '好的',
        })
        $("#myModal").modal('show')
        return
      }
      if (this.msgForm.context === '') {
        modalBox({
          title: '提示',
          content: "请填写留言！",
          ok: '好的',
        })
        $("#myModal").modal('show')
        return
      }

      var this1 = this
      this.$http({
        method: 'post',
        url: '/api/msg/insertMsg',
        data: this.msgForm
      }).then((res) =>{
        if (res.data.status) {
          modalBox({
            title: '操作提示',
            content: res.data.msg,
            ok: '好的',
            handleOk(){
              var _this = this1
              _this.msgForm.msgName = ''
              _this.msgForm.tel = ''
              _this.msgForm.context = ''
            }
          })
          $("#myModal").modal('show')
        }
      })
    },
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>