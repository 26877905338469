import Vue from 'vue'
import Modal from './modal'

export var modalBox = (function () {

  var defaults = {
    title: '',
    content: '',
    cancel: '',
    ok: '',
    handleCancel: null,
    handleOk: null
  };

  return function (opts) { //配置参数
    for (var attr in opts) {
      defaults[attr] = opts[attr]
    }

    var MyComponent = Vue.extend(Modal)

    var vm = new MyComponent({
      el: document.createElement('div'),
      data: {
        title: defaults.title,
        content: defaults.content,
        cancel: defaults.cancel,
        ok: defaults.ok
      },
      methods: {
        handleCancel() {
          defaults.handleCancel && defaults.handleCancel.call(this)
          this.$("#myModal").modal('hide')
          document.body.removeChild( vm.$el )
        },
        handleOk() {
          defaults.handleOk && defaults.handleOk.call(this)
          this.$("#myModal").modal('hide')
          document.body.removeChild( vm.$el )
        },

      }
    });
    document.body.appendChild( vm.$el)


  }
})()
